import { Routes, RouteObject } from "react-router-dom"
import LoginNew from "../pages/register/NewLogin"
import RegisterOtp from "../pages/register/RegisterOtp"
import RegisterPwd from "../pages/register/RegisterPwd"


export const RegisterRoute: RouteObject = {
   path: "/register",
   children: [
      {
         path: "/register",
         element: <LoginNew />
      },
      {
         path: "/register/otp",
         element: <RegisterOtp />
      },
      {
         path: "/register/password",
         element: <RegisterPwd />
      },
   ]
}