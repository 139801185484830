import react, { useState } from 'react'
import { useStoreSelector } from '../../store/useStore'
import userAdminFunction from '../../hooks/userAdminFunction'
import { NavLink } from 'react-router-dom'
const logo: string = require('../../assets/icons/logo.svg').default
export default () => {
   const [isLoading, setIsLoading] = useState(false)
   const { createAdminReset } = userAdminFunction()
   const [loginData, setLoginData] = useState({ email: '' })

   return (
      <div className="container-fluid h-100 nbglogin login100 vheight">
         <div>
            <nav className="navbar navbar-expand-sm fixed-top navbar-light logMenuBg">
               <div className="container">
                  <div className='row newstyle'>
                     <div className='col-lg-4 d-flex'>
                        <a className="navbar-brand text-white" href="#">www.aipecgroup.com</a>
                        <a className="navbar-brand" href="#"><span className="text-white">●</span></a>
                        <a className="navbar-brand text-white" href="#">Aipec Finance Dashboard</a>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
         <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12">
               <div className="card main-width">
                  <div className="card-body  p-0">
                     <div className="newWidth">
                        <div className="card mNewCard">
                           <div className="card-body">
                              <div className="d-flex align-items-center justify-content-center mb-2">
                                 <a href="#">
                                    <img src={logo} />
                                 </a>
                              </div>
                              <h2 className='nppColor'>Reset Password</h2>
                              <span>Add your details to get started</span>
                              <form className="mt-4">
                                 <div className="form-group mb-2">
                                    <label>Email address</label>
                                    <input type="email" onChange={(e) => setLoginData(edata => ({ ...edata, email: e.target.value.trim() }))} className="form-control" id="exampleInputEmail1" placeholder="Enter Email" />
                                 </div>
                              </form>
                              <div>
                                 <button type='button' disabled={isLoading || false} onClick={() => createAdminReset(loginData)} className="btn btn-primary mb-4 mt-4 btnGreen nnbtn btnpadding">Proceed</button>
                                 <p style={{ marginTop: "-12px", textAlign: 'center' }}>Got an account? <NavLink to="/" className="text-primary textGreen font-w500 showCursor">Login</NavLink></p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}