// let baseUrl = "http://localhost:9204/admin";
// let baseUrl = "https://aipec-finance-api.onrender.com/admin";
let baseUrl = "https://api2.aipecdashboard.com/admin";

const APIendPoint = {
   admin: {
      login: baseUrl + "/auths/login",
      pwdReset: baseUrl + "/auths/forgot-password",
      profile: baseUrl + "/accounts/profile",
      getAdmin: baseUrl,
   },

};

export default APIendPoint;
