import { CSSProperties } from "react";

const loaderDefault = require('../assets/images/loader.gif');
export default (props: { style?: CSSProperties }) => {
   return (
      <div style={{
         position: 'fixed', top: 0, left: 0,
         // backgroundColor: 'rgba(0, 0, 0, 0.1)',
         width: '100%',
         height: '100%', zIndex: 9999,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         ...props.style
      }}>
         <div style={{ width: '20%', background: "#FFFFFF", borderRadius: "10px", padding: "20px", height: '96px', boxShadow: '0px 4px 2px 0px #0000000F' }}>
            <div className="d-flex align-items-center" style={{ height: '96px', marginTop: '-20px' }}>
               <img className="negativeML50" src={loaderDefault} />
               <p className="loaderP negativeML50">Loading... Please wait</p>
            </div>
         </div>
      </div>
   )
}