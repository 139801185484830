import react, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useStoreSelector } from '../../store/useStore';
const logo: string = require('../../assets/icons/logo.svg').default
export default () => {
   let token = localStorage.getItem("__ktoken");
   const { userData } = useStoreSelector(["userData"])
   let env = 'dev'
   // let mainDashboard = env == 'dev' ? 'http://localhost:3003' : 'https://aipec-front.vercel.app'
   // let depotDashboard = env == 'dev' ? 'http://localhost:3002' : "https://aipec-depot.vercel.app"
   // let financeDashboard = env == 'dev' ? 'http://localhost:3001' : "https://aipecdashboard.vercel.app"

   let mainDashboard = 'https://dev.aipecdashboard.com'
   let depotDashboard = "https://depot.aipecdashboard.com"
   let financeDashboard = "https://finance.aipecdashboard.com"

   return (
      <div className="container-fluid h-100 nbglogin">
         <div>
            <nav className="navbar navbar-expand-sm fixed-top navbar-light logMenuBg">
               <div className="container">
                  <div className='row newstyle'>
                     <div className='col-lg-4 d-flex'>
                        <a className="navbar-brand text-white" href="#">www.aipecgroup.com</a>
                        <a className="navbar-brand" href="#"><span className="text-white">●</span></a>
                        <a className="navbar-brand text-white" href="#">Aipec Data Centre</a>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
         <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12">
               <div className="card main-width">
                  <div className="card-body  p-0">
                     <div className="newWidth">
                        <div className="card mNewCard">
                           <div className="card-body">
                              <div className="d-flex align-items-center justify-content-center mb-4">
                                 <a href="#">
                                    <img src={logo} />
                                 </a>
                              </div>
                              <h2 className='nppColor text-center'>Welcome Back</h2>
                              <p className='text-center' style={{ fontSize: '14px' }}>Where would you like to go?</p>
                              <div className="" style={{ marginTop: '20px' }}>
                                 <div className='text-center'>
                                    <p className="bgo"><a href={`${mainDashboard}/gas?access=${token}`} target="_blank">Main Dashboard</a></p>
                                    {userData?.department === 'Finance' || userData?.user_type === 'superadmin' ?
                                       <p className="bgo"><a href={`${financeDashboard}/dashboard?access=${token}`} target="_blank">Finance Dashboard</a></p> : ''
                                    }
                                    {userData?.department === 'Depot' || userData?.department === 'Finance' || userData?.user_type === 'superadmin' || userData?.user_type === 'admin' || userData?.user_type === 'data-officer' ?
                                       <p className="bgo"><a href={`${depotDashboard}/dashboard?access=${token}`} target="_blank">Depot Dashboard</a></p> : ''
                                    }
                                 </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between flex-wrap" style={{ marginTop: "70px" }}>
                                 {/* <p style={{ marginTop: "-12px" }}><NavLink to="/gas" className="text-primary textGreen font-w500 showCursor">Skip</NavLink></p> */}
                                 <NavLink style={{ marginLeft: "auto" }} className="btn btn-primary mb-4 btnGreen bgbtn" to="/">Logout</NavLink>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}