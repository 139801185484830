import react, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useStoreSelector } from '../../store/useStore'
import userAdminFunction from '../../hooks/userAdminFunction'
import { NavLink } from 'react-router-dom'
const logo: string = require('../../assets/icons/logo.svg').default
export default () => {
   const [isLoading, setIsLoading] = useState(false)
   const { createAdminOTPLogin, createAdminReset } = userAdminFunction()
   const { userData } = useStoreSelector(["userData"])
   const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
   const [timeLeft, setTimeLeft] = useState<number>(60);
   // Handle change for OTP inputs
   const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
      const value = e.target.value.slice(0, 1); // Only allow one character per field
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically move to the next field if a digit is entered
      if (value !== '' && index < 5) {
         const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement;
         nextInput?.focus();
      }
   };

   // Handle backspace key to move to the previous input
   const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number): void => {
      if (e.key === 'Backspace' && otp[index] === '') {
         if (index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement;
            prevInput?.focus();
         }
      }
   };
   const sendOTP = () => {
      if (otp.every((digit) => digit !== '')) {
         const otpString = otp.join('');
         createAdminOTPLogin(otpString, userData?.data?.email)
      }
   }

   useEffect(() => {
      // If timeLeft is 0, stop the timer
      if (timeLeft <= 0) return;

      // Set an interval to update the timer every second
      const timerInterval = setInterval(() => {
         setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      // Clear the interval when the component unmounts or when timeLeft reaches 0
      return () => clearInterval(timerInterval);
   }, [timeLeft]); // Dependency array to run the effect when `timeLeft` changes

   // Format time as MM:SS
   const minutes = Math.floor(timeLeft / 60);
   const seconds = timeLeft % 60;

   return (
      <div className="container-fluid h-100 nbglogin">
         <div>
            <nav className="navbar navbar-expand-sm fixed-top navbar-light logMenuBg">
               <div className="container">
                  <div className='row newstyle'>
                     <div className='col-lg-4 d-flex'>
                        <a className="navbar-brand text-white" href="#">www.aipecgroup.com</a>
                        <a className="navbar-brand" href="#"><span className="text-white">●</span></a>
                        <a className="navbar-brand text-white" href="#">Aipec Finance Dashboard</a>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
         <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12">
               <div className="card main-width">
                  <div className="card-body  p-0">
                     <div className="newWidth">
                        <div className="card mNewCard">
                           <div className="card-body">
                              <div className="d-flex align-items-center justify-content-center mb-4">
                                 <a href="#">
                                    <img src={logo} />
                                 </a>
                              </div>
                              <h2 className='nppColor'>Enter OTP</h2>
                              <span className='otpText'>Enter the code sent to your email address - <span className='colorGreenOtp'>{userData?.data?.email || ''}</span></span>
                              <div className="dot-container mt-3 mb-3">
                                 <div className="dot activeDots"></div>
                                 <div className="dot activeDots"></div>
                                 <div className="dot nonactiveDots"></div>
                              </div>
                              <form className="mt-4 mb-4">
                                 <div className="form-group mb-2">
                                    <div className='otpcontainer'>
                                       {otp.map((digit, index) => (
                                          <input className='otpinput'
                                             key={index}
                                             id={`otp-input-${index}`}
                                             type="text"
                                             value={digit}
                                             onChange={(e) => handleChange(e, index)}
                                             onKeyDown={(e) => {
                                                handleKeyDown(e, index)
                                             }}
                                             onKeyUp={() => sendOTP()}
                                             maxLength={1}
                                             autoFocus={index === 0}
                                          />
                                       ))}
                                    </div>
                                 </div>
                              </form>
                              <div className='d-flex flex-wrap justContent'>
                                 <p className='otpText' style={{ textAlign: 'center' }}>Didn't get the code? <span className='colorGreenOtp showCursor' onClick={() => createAdminReset(userData?.data)}>Resend</span></p>
                                 <p className='otpText' style={{ textAlign: 'center' }}> {`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}