import react, { useState } from 'react'
import { useStoreSelector } from '../../store/useStore'
import userAdminFunction from '../../hooks/userAdminFunction'
import { NavLink } from 'react-router-dom'
const logo: string = require('../../assets/icons/logo.svg').default
export default () => {
   const [isLoading, setIsLoading] = useState(false)
   const [uppercase, setUppercase] = useState(false)
   const [charact, setCharact] = useState(false)
   const { NewAccountPassword } = userAdminFunction()
   const [loginData, setLoginData] = useState({ password: '', confirmPassword: '', email: '' })
   // Function to validate password
   const validatePassword = (password: string): string => {
      const upperCaseRegex = /[A-Z]/;  // Checks for uppercase letters
      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;  // Checks for special characters

      if (!upperCaseRegex.test(password)) {
         setUppercase(false)
      }
      if (upperCaseRegex.test(password)) {
         setUppercase(true)
      }

      if (!specialCharRegex.test(password)) {
         setCharact(false)
      }
      if (specialCharRegex.test(password)) {
         setCharact(true)
      }

      return ''; // Return empty string if password is valid
   };

   // Handle change in password input
   const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newPassword = e.target.value;
      // setLoginData((data => ({ ...data, password: newPassword })));
      validatePassword(newPassword);
   };
   return (
      <div className="container-fluid h-100 nbglogin">
         <div>
            <nav className="navbar navbar-expand-sm fixed-top navbar-light logMenuBg">
               <div className="container">
                  <div className='row newstyle'>
                     <div className='col-lg-4 d-flex'>
                        <a className="navbar-brand text-white" href="#">www.aipecgroup.com</a>
                        <a className="navbar-brand" href="#"><span className="text-white">●</span></a>
                        <a className="navbar-brand text-white" href="#">Aipec Finance Dashboard</a>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
         <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12">
               <div className="card main-width">
                  <div className="card-body  p-0">
                     <div className="newWidth">
                        <div className="card mNewCard">
                           <div className="card-body">
                              <div className="d-flex align-items-center justify-content-center mb-4">
                                 <a href="#">
                                    <img src={logo} />
                                 </a>
                              </div>
                              <h2 className='nppColor'>Password</h2>
                              <span>Set up your password for your security</span>
                              <div className="dot-container mt-3 mb-3">
                                 <div className="dot activeDots"></div>
                                 <div className="dot activeDots"></div>
                                 <div className="dot activeDots"></div>
                              </div>

                              <form className="mt-4">
                                 <div className="form-group mb-2">
                                    <label>Password</label>
                                    <input type="password" onChange={(e) => {
                                       handlePasswordChange(e)
                                       setLoginData(edata => ({ ...edata, password: e.target.value.trim() }))
                                    }} className="form-control" placeholder="" />
                                 </div>
                                 <div className="form-group mb-2">
                                    <label>Confirm Password</label>
                                    <input type="password" onChange={(e) => setLoginData(edata => ({ ...edata, confirmPassword: e.target.value.trim() }))} className="form-control" placeholder="" />
                                 </div>
                              </form>
                              <div>
                                 <div className="d-flex">
                                    <div className="form-check custom-checkbox mb-3 m-l-r-20">
                                       <input type="checkbox" className="form-check-input fpinput" checked={uppercase ? true : false} required />
                                       <label className="form-check-label fplabel" >Uppercase letters</label>
                                    </div>
                                    <div className="form-check custom-checkbox mb-3" style={{ marginTop: "1px" }}>
                                       <input type="checkbox" className="form-check-input fpinput" checked={charact ? true : false} required />
                                       <label className="form-check-label fplabel">Special characters</label>
                                    </div>
                                 </div>
                              </div>
                              <div>
                                 <button type='button' disabled={isLoading || false} onClick={() => NewAccountPassword(loginData.password, loginData.confirmPassword)} className="btn btn-primary mb-4 mt-4 btnGreen nnbtn btnpadding">Proceed</button>
                                 <p style={{ marginTop: "-12px", textAlign: 'center' }}>Got an account? <NavLink to="/" className="text-primary textGreen font-w500 showCursor">Login</NavLink></p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}