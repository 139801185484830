import { useEffect } from 'react';
import './App.css';
import RouteComponent from "./routeList/index"
import { useStoreSelector } from './store/useStore';
import { BrowserRouter, Route, Router, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewLogin from './pages/register/NewLogin';
import LoginIndex from './pages/login/LoginIndex';
import Loader from './components/Loader';
import helpers from './utils/helpers';
import APIendPoint from './utils/apiEndPoints';
import RegisterOtp from './pages/register/RegisterOtp';
import RegisterPwd from './pages/register/RegisterPwd';
import ResetPwd from './pages/login/ResetPwd';
import LoginPwd from './pages/login/LoginPwd';
import LoginOtp from './pages/login/LoginOtp';
// const navigate = useNavigate();
export default () => {
   // const navigate = useNavigate();
   const location = window.location.pathname
   // console.log(location)
   const { userData, isTokenLoader, setIsTokenLoader, setUserData, } = useStoreSelector(["userData", "isTokenLoader", "setIsTokenLoader", "setUserData"])
   // console.log('here 1')
   const handleNavigation = () => {
      // console.log(location)
      if (location === '/') {
         // console.log('llld')
         return;
      } else {
         window.location.href = '/'
      }
   }
   let token = localStorage.getItem("__ktoken");
   const getprofile = async () => {
      if (!token) {
         // navigate("/login");
         // console.log('here 2')
         return;
      }
      setIsTokenLoader(true)

      let sendRequest = await helpers
         .sendRequest({
            url: APIendPoint.admin.profile,
            method: "GET",
            token: token,
         })
         .catch((e) => e);
      // return
      setIsTokenLoader(false)

      if (sendRequest && sendRequest.status_code !== 200) {
         localStorage.removeItem("__ktoken");
         // console.log('here')
         // navigate("/login");
         handleNavigation()
         return;
         // return helpers.showErrorMessage(sendRequest);
      }
      setUserData({ ...sendRequest.data.data, token: token });
   };

   useEffect(() => {

      if (token) {
         getprofile()
      } else {
         // navigate("/");
         console.log('oyear')
         handleNavigation()
      }
   }, [])
   return (
      <div>


         {
            isTokenLoader ?
               <Loader style={{ marginTop: "25%" }} /> :
               userData?.auth_id ?
                  <RouteComponent /> :
                  <BrowserRouter>
                     <Routes><Route path="/" Component={() => <LoginIndex />} /></Routes>
                     <Routes><Route path="/login" Component={() => <LoginIndex />} /></Routes>
                     <Routes><Route path="/login/reset" Component={() => <ResetPwd />} /></Routes>
                     <Routes><Route path="/login/password" Component={() => <LoginPwd />} /></Routes>
                     <Routes><Route path="/login/otp" Component={() => <LoginOtp />} /></Routes>
                     <Routes><Route path="/register" Component={() => <NewLogin />} /></Routes>
                     <Routes><Route path="/register/otp" Component={() => <RegisterOtp />} /></Routes>
                     <Routes><Route path="/register/password" Component={() => <RegisterPwd />} /></Routes>

                  </BrowserRouter>
         }

         <ToastContainer style={{ width: "auto" }} pauseOnHover newestOnTop />
      </div>
   )
}
