import { useNavigate } from "react-router-dom";
import { useStoreSelector } from "../store/useStore";
import APIendPoint from "../utils/apiEndPoints";
import helpers from "../utils/helpers";

export default () => {
   const navigate = useNavigate();
   const {
      setIsLoading,
      setUserData,
      setAdminData,
      setAdminPageCount,
   } = useStoreSelector([
      "setIsLoading",
      "setUserData",
      "setAdminData",
      "setAdminPageCount",
   ]);
   const login = async (
      email: string,
      password: string,
      setIsLoading: Function
   ) => {
      if (!email) {
         return helpers.showToast("error", "Please input a valid email");
      }
      if (!password) {
         return helpers.showToast("error", "Please input a valid password");
      }
      helpers.showToast("loading", "Loading please wait")
      setIsLoading(true);
      let sendRequest = await helpers
         .sendRequest({
            url: APIendPoint.admin.login,
            method: "POST",
            json: { email, password },
         })
         .catch((e) => e);

      if (sendRequest) {
         setIsLoading(false);
         if (sendRequest.status_code === 200) {
            helpers.showToast("success", "success")
            setUserData(sendRequest.data.data);
            localStorage.setItem("__ktoken", sendRequest.data.data.token);
            localStorage.setItem("__kauth_id", sendRequest.data.data.auth_id);
            navigate("/dashboard");
         } else {
            // console.log(sendRequest)
            return helpers.showToast("error", sendRequest?.data ? sendRequest?.data?.message : "Looks like you are experiencing a network issue");
         }
      }
   };

   const getprofile = async (type?: string) => {
      let token = localStorage.getItem("__ktoken");
      let authId = localStorage.getItem("__kauth_id");
      if (!token) {
         navigate("/");
         return;
      }
      let sendRequest = await helpers
         .sendRequest({
            url: APIendPoint.admin.profile,
            method: "GET",
            token: token,
         })
         .catch((e) => e);
      // return
      if (sendRequest && sendRequest.status_code !== 200) {
         localStorage.removeItem("__ktoken");
         navigate("/");
         return;
         // return helpers.showErrorMessage(sendRequest);
      }
      setUserData({ ...sendRequest.data.data, token: token });
   };


   type DropdownItems = {
      name?: string;
   };

   const createAdmin = async (
      data: {
         firstname: string;
         lastname: string;
         email?: string;
         role?: string;
         department?: string;
      }
   ) => {
      if (!data?.firstname) {
         return helpers.showToast("error", "First name is required");
      }
      if (!data?.lastname) {
         return helpers.showToast("error", "Last name is required");
      }
      if (!data?.email) {
         return helpers.showToast("error", "email is required");
      }
      if (!data?.role) {
         return helpers.showToast("error", "role is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/auths/register`,
            method: "POST",
            json: {
               firstname: data?.firstname,
               lastname: data?.lastname,
               email: data?.email,
               department: data?.department,
               role: data?.role
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "verification email sent")
      setUserData(sendRequest.data.data);
      navigate(`/register/otp`)
   };
   const createAdminOTP = async (
      otp: string,
      email: string
   ) => {
      if (!email) {
         return helpers.showToast("error", "email is required");
      }
      if (!otp) {
         return helpers.showToast("error", "otp is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/auths/complete-register`,
            method: "POST",
            json: {
               email: email,
               otp: otp
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "Please set your password")
      setUserData(sendRequest.data.data);
      localStorage.setItem("__vtoken", sendRequest.data.data.token);
      navigate(`/register/password`)
   };

   const NewAccountPassword = async (
      password: string,
      confirmPassword: string,
   ) => {
      let token = localStorage.getItem("__vtoken");
      if (!token) {
         navigate("/");
         return;
      }
      if (!password) {
         return helpers.showToast("error", "password is required");
      }
      if (confirmPassword !== password) {
         return helpers.showToast(
            "error",
            "password and confirm password do not match"
         );
      }

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/accounts/password`,
            method: "PUT",
            token: token,
            json: {
               password: password,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "Your request was successful");
      localStorage.removeItem("__vtoken");
      navigate("/");
   };


   const resetNewPassword = async (
      email: string,
      password: string,
      confirmPassword: string,
      onPress?: Function
   ) => {
      if (!email) {
         return helpers.showToast("error", "email was not captured");
      }
      if (!password) {
         return helpers.showToast("error", "password is required");
      }
      if (confirmPassword != password) {
         return helpers.showToast(
            "error",
            "password and confirm password do not match"
         );
      }

      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.pwdReset}/reset-password`,
            method: "POST",
            json: {
               email: email,
               password: password,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "Password was changed successfully");
      onPress && onPress();
   };

   const createAdminReset = async (
      data: {
         email?: string;
      }
   ) => {

      if (!data?.email) {
         return helpers.showToast("error", "email is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/auths/register-pwd-otp`,
            method: "POST",
            json: {
               email: data?.email,
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "verification email sent")
      setUserData(sendRequest.data.data);
      navigate(`/login/otp`)
   };
   const createAdminOTPLogin = async (
      otp: string,
      email: string
   ) => {
      if (!email) {
         return helpers.showToast("error", "email is required");
      }
      if (!otp) {
         return helpers.showToast("error", "otp is required");
      }
      helpers.showToast("loading", "Loading");
      let sendRequest = await helpers
         .sendRequest({
            url: `${APIendPoint.admin.getAdmin}/auths/complete-pwd-otp`,
            method: "POST",
            json: {
               email: email,
               otp: otp
            },
         })
         .catch((e) => e);
      //  setIsLoading(false);
      if (sendRequest && sendRequest.status_code !== 200) {
         if (sendRequest) {
            return helpers.showToast("error", sendRequest.data?.message);
         } else {
            return helpers.showToast("error", "Please check your network and try again");
         }
      }
      helpers.showToast("success", "Please set your password")
      setUserData(sendRequest.data.data);
      localStorage.setItem("__vtoken", sendRequest.data.data.token);
      navigate(`/login/password`)
   };

   return {
      login,
      getprofile,
      createAdmin,
      resetNewPassword,
      createAdminOTP,
      NewAccountPassword,
      createAdminOTPLogin,
      createAdminReset
   };
};
