import { Routes, RouteObject } from "react-router-dom"
import LoginIndex from "../pages/login/LoginIndex"
import LoginNew from "../pages/register/NewLogin"
import LoginOtp from "../pages/login/LoginOtp"
import LoginPwd from "../pages/login/LoginPwd"
import ResetPwd from "../pages/login/ResetPwd"


export const LoginRoute: RouteObject = {
   path: "/",
   children: [
      {
         path: "/",
         element: <LoginIndex />
      },
      {
         path: "/login/otp",
         element: <LoginOtp />
      },
      {
         path: "/login/password",
         element: <LoginPwd />
      },
      {
         path: "/login/reset",
         element: <ResetPwd />
      },
   ]
}