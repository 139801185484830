import { BrowserRouter, createBrowserRouter, RouterProvider } from "react-router-dom"
import { LoginRoute } from "./login-route"
import { DashboardRoute } from "./dashboard-route"
import { RegisterRoute } from "./register-route"


const ComponentRouter = [
   DashboardRoute,
   RegisterRoute,
   LoginRoute,
]


export default () => {

   const appRouter = createBrowserRouter(ComponentRouter)
   return (
      <RouterProvider router={appRouter} fallbackElement={<div>Not found</div>} />
   )
}